@import url("./fonts/fonts.css");

:root {
  --bg-color-white: white;
  --color-red: #4a0f0f;
  --color-red-opacity: rgba(74, 15, 15, 0.9);
  --secondary-bg-color: lightgreen;
  --color-beige: #ede6df;
}

html,
body {
  background: linear-gradient(
    to top,
    var(--color-beige) 30%,
    rgba(255, 255, 255, 0)
  ) !important;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif !important;
}

.headline {
  color: var(--color-red);
  font-size: 3rem;
  font-weight: 500;
}

.subline {
  font-size: 1.4rem;
}

.picture-frame {
  border-radius: 50%;
  margin-bottom: 20px;
  height: 41vmin;
  width: 41vmin;
  border: solid #4a0f0f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.links {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
}

.App-header {
  display: flex;
  flex-direction: column;
  margin-top: 40vh;
  margin-bottom: 20px;
  margin-left: 5vw;
  margin-right: 5vw;
  align-items: "center";
  justify-content: "center";
}

@media (max-width: 960px) {
  .App-header {
    text-align: start;
    margin-top: 20vh;
    margin-bottom: 5px;
  }
}

.App-link {
  color: #61dafb;
}

.contact-wrapper {
  display: flex;
  justify-content: center;
}
.contact-grid {
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: 20px;
  margin-bottom: 60px;
}

.panels {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
}

.tab {
  background-color: #f9e4d8;
  border: #020917;
  color: #4a0f0f;
}

.tab[aria-selected="true"] {
  color: #4a0f0f;
}

.tab:active {
  background-color: transparent !important;
}

.tablist {
  border: none !important;
}

h1 {
  font-size: 1.5rem;
  font-weight: 300;
  color: #4a0f0f;
}

h4 {
  margin-top: 0;
  font-weight: 600;
}

h2 {
  font-weight: 600;
}

h3 {
  font-size: 1.3rem;
}

.work-panel {
  margin-top: 0px !important;
}

.about-text-wrapper {
  display: flex;
  text-align: start;
  justify-content: center;
}

.about-text {
  max-width: 800px;
}

.link {
  text-decoration: underline !important;
}

.about-text-paragraph {
  margin-bottom: 10px;
}

.contacts {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 30px;
  margin-bottom: 40px;
}

.contact-item {
  display: flex;
  gap: 5px;
}

.cookie-banner {
  position: fixed;
  bottom: 10px;
  left: 10px;
  padding: 5px;
  display: flex;
  gap: 5px;
  background-color: white;
}
