.project-grid {
  width: 80vw;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  margin-bottom: 200px;
}

.project-title-link {
  cursor: pointer;
}

.project-title-picture {
  position: relative;
  margin-top: 20px;
  width: 100%;
  text-align: start;
}

.project-grid > a:nth-child(2n) {
  margin-top: 50%;
}

@media (max-width: 960px) {
  .project-grid {
    width: 80vw;
    grid-template-columns: 1fr;
    gap: 5px;
    margin-bottom: 200px;
  }
  .project-grid > a:nth-child(2n) {
    margin-top: 0px;
  }
}

.project-title-text{
margin-top: 10px;
}

.project-title {
  font-size: 1.1rem !important;
  font-weight: 600;
}

.project-wrapper {
  display: flex;
  justify-content: center;
}

.link-project-title {
  width: 100%;
}
