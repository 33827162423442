
.imprint{
    margin: 5vw;
    margin-top: 200px;
}

.imprint p{
    margin-bottom: 20px;
    font-size: 0.9rem;
    max-width: 800px;
}

.imprint h1{
    margin-bottom: 10px;
}

.imprint h2{
    font-size: 1.1rem;
    font-weight: 400;
}

.imprint h3{
    font-size: 1rem;
    font-weight: 600;
}
