@font-face {
    font-family: 'Montserrat';
    src: url('subset-Montserrat-Medium.woff2') format('woff2'),
        url('subset-Montserrat-Medium.woff') format('woff'),
        url('subset-Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('subset-Montserrat-Regular.woff2') format('woff2'),
        url('subset-Montserrat-Regular.woff') format('woff'),
        url('subset-Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('subset-Montserrat-Light.woff2') format('woff2'),
        url('subset-Montserrat-Light.woff') format('woff'),
        url('subset-Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('subset-Montserrat-SemiBold.woff2') format('woff2'),
        url('subset-Montserrat-SemiBold.woff') format('woff'),
        url('subset-Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

