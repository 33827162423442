.project-page-body {
  display: flex;
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.previous-project {
  position: fixed;
  left: 2rem;
  bottom: 50vh;
}

.next-project {
  position: fixed;
  font-size: 1rem;
  right: 2rem;
  bottom: 50vh;
  display: flex; /* Use flexbox for better alignment */
  align-items: center; /* Center items vertically */
}

.next-project svg,
.previous-project svg {
  height: 1.5rem;
}

.project-page-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 10vw;
  margin-left: 10vw;
  margin-top: 40px;
}

.project-page-wrapper h1,
.project-page-wrapper h2 {
  padding-left: 1rem;
}

.project-pictures-grid {
  width: 80vw;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  justify-items: center;
}

.project-pictures-grid > div:nth-child(2n) {
  margin-top: 50%;
}

.project-picture {
  width: 100%;
}

.project-picture-long {
  grid-column: 1/3;
  margin-top: 40px !important;
}

.project-video {
  display: flex;
  width: 100%;
  padding-top: 20px;
  grid-column: 1/3;
  height: 70vh;
  margin-top: 20px !important;
}

.project-video-iframe {
  width: 100%;
  height: 100%;
}

.project-picture > * {
  width: 100%;
}

.project-description {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  max-width: 900px;
  padding-left: 1rem;
}

.project-description-paragraph {
  margin-top: 6px;
}

.link-to-project {
  text-decoration: underline !important;
  margin-right: 10px;
  font-weight: 600;
}

.project-links {
  display: flex;
  margin-top: 10px;
  margin-bottom: 70px;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}

@media (max-width: 960px) {
  .project-pictures-grid {
    width: 80vw;
    grid-template-columns: 1fr;
    gap: 5px;
    margin-bottom: 50px;
  }

  .project-video {
    display: flex;
    width: 100%;
    grid-column: 1/3;
    height: 112%;
  }

  .project-picture-long{
grid-column: 1/2;
margin-top: 10px !important;
  }

  .project-pictures-grid > div:nth-child(2n) {
    margin-top: 0;
  }

  .previous-project {
    left: 5px;
  }

  .next-project {
    right: 5px;
  }
}
