.navbar{
    height: 4rem;
    padding-bottom: 0.5rem ;
    padding-top: 1rem ;
    padding-left: 0.5rem ;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: start;
    margin-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 1.5rem;
    font-weight:100;
    font-size: 1.5rem;
    color: #4a0f0f !important;
    position:fixed;
    top:0;
    opacity: 0.8;
    z-index: 2;
    width:100%;   
}

.contacts-navbar{
    display: flex;
  }

  @media (max-width: 960px) {
    .navbar{
        background-color: var(--color-beige);
    }
  }